body {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 14px;
}

@font-face {
    font-family: "MyFont";
    src:url('@/assets/fonts/BebasNeuePro-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display:swap;
}


